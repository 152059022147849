.header {

}

.header .upper {
    background-color: #3f5070;
    height: 65px;

}

.header .middle {
    background-color: white;
    height: 150px;
    justify-content: space-between;
}

.header .bottom {
    background-color: #e9f1f4;
    height: 60px;
}

.main {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    height: 100%;
    max-width: 1300px;
    margin: 0 auto;
}

.minPros {
    width: 400px;

}

.minPros a {
    height: 65px;
    max-width: 400px;
    display: flex;
    font-size: 12px;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    text-decoration-line: none;
    cursor: pointer;
    color: white;
}

.minPros img {
    height: 45px;
}

.upper .main .buttons {
    width: 575px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.searchButton {
    width: 95px;
    color: white;
    font-size: 16px;
    height: 100%;
    display: flex;
    align-items: center;
    margin-right: 10px;
    cursor: pointer;
}

.visionButton {
    width: 280px;
    background-color: #df7a9a;
    color: white;
    height: 50px;
    border-radius: 15px;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    cursor: pointer;
}

.buttons .btn {
    height: 48px;
    width: 48px;
    margin-right: 4px;
    fill: white;
}

.buttons .btn a {
    height: 100%;
    width: 100%;
    border: 1px solid white;
    border-radius: 15px;
    fill: white;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 40px;
    text-decoration-line: none;
    font-weight: bold;
}

.buttons .btn a:hover {
    fill: #df7a9a;
    color: #df7a9a;
    border: 1px solid #df7a9a;
}

.icon {
    width: 70%;
}

.icon a img {
    width: 100%;
    max-height: 100px;
}

.cabinet {
    display: flex;
    align-items: center;
    height: 100%;
    justify-content: right;
}

.cabinet a {
    color: black;
    text-decoration-line: none;
    font-size: 22px;
    height: 35px;
    display: flex;
    align-items: center;
    gap: 5px;

}

.cabinet a:hover {
    color: #cb2d5f;
}

.cabinet a img {
    height: 35px;
}

.bottomUrl a {
    display: block;
    text-decoration-line: none;
    color: black;
    font-size: 20px;
}

@media screen and (max-width: 1200px) {
    .bottomUrl a {

        font-size: 16px;
    }

}

.mobileHeader {

}

.mobileHeader .upper {
    background-color: #3f5070;
    height: max-content;
}

.mobileHeader .upper .ministry {
    padding-top: 10px;
    padding-bottom: 5px;
    max-width: 90%;
    margin: 0 auto;
}

.mobileHeader .upper .ministry img {
    height: 45px;
    padding-right: 10px;
}


.mobileHeader .upper .ministry a {
    display: flex;
    font-size: 14px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-decoration-line: none;
    cursor: pointer;
    color: white;
}

.mobileHeader .upper .social {
    padding-top: 5px;
    padding-bottom: 5px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.mobileHeader .upper .social .btn {
    height: 48px;
    width: 48px;
    margin-right: 10px;
    fill: white;
}

.mobileHeader .upper .social .btn a {
    height: 100%;
    width: 100%;
    border: 1px solid white;
    border-radius: 15px;
    fill: white;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 40px;
    text-decoration-line: none;
    font-weight: bold;
}

.mobileHeader .upper .social .btn a:hover {
    fill: #df7a9a;
    color: #df7a9a;
    border: 1px solid #df7a9a;
}

.mobileHeader .upper .search {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 15px;
    padding-bottom: 10px;

}

.mobileHeader .upper .search .info {
    width: 90%;
    max-width: 450px;
    border-bottom: 1px white solid;
    display: flex;
    justify-content: space-between;
    align-items: center;
}


.mobileHeader .upper .search .info input {

    color: white;
    border: none;
    padding: 2px;
    font-size: 18px;
    background: transparent;
    -webkit-appearance: textfield;
}

.mobileHeader .upper .search .info input:focus {
    outline: none;
}
.mobileHeader .upper .search .info input::-webkit-input-placeholder {
    color: white;
    font-weight: normal;
}

.mobileHeader .upper .search .info input::-webkit-search-decoration,
.mobileHeader .upper .search .info input::-webkit-search-cancel-button {
    -webkit-appearance: none;
}

.mobileHeader .middle {
    padding-top: 10px;
    padding-bottom: 10px;
    width: 100%;
    background-color: white;
}

.mobileHeader .middle .info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    width: 90%;
}

.mobileHeader .middle .info img {
    width: 66%;
    max-width: 600px;
}

.mobileHeader .middle .info .mobileCabinet img{
    height: 48px;
    width: calc(48px * 355 / 319);
}

.mobileMenu {
    transition: all .2s;
}

.mobileMenu ul {
    background-color: #e9f1f4;
    margin: 0;
    padding: 10px 0 20px;
}

.mobileMenu li {
    width: 90%;
    list-style-type: none;
    margin: 0 auto;
    padding-top: 5px;
    padding-bottom: 5px;
}

.mobileMenu li a {
    text-decoration-line: none;
    color: black;
    font-size: 17px;
}