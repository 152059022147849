.footer {
    background-color: #3f5070;
    width: 100%;
    position: absolute;
}

.footer .info {
    max-width: 1300px;
    width: 90%;
    margin: 0 auto;
    height: 100px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.footer .info a {
    color: white;
    width: 15%;
    font-size: 18px;
    text-decoration-line: underline;
    cursor: pointer;

    display: flex;
    align-items: center;
}

.footer .info .title {
    height: 100%;
    display: flex;
    flex-direction: column;
    width: 65%;
    color: white;
}

.tech {
    color: white;
    font-size: 18px;
    row-gap: 10px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.footer .info .title .upper {
    width: 100%;
    height: 50%;
    display: flex;
    justify-content: flex-end;
}

.footer .info .title .upper a{
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: flex-end;
    flex-direction: column;
    display: flex;
    text-decoration-line: none;
    cursor: pointer;
    font-size: 16px;
}

.footer .info  .title .bottom {
    padding-top: 10px;
    font-size: 16px;
    height: 40%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    text-decoration-line: none;
    cursor: pointer;
}

.footer .info .icons {
    min-width: 10%;
    max-width: 20%;
    gap: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 100%;
}

.footer .info .icons .item {

    height: 50px;
    width: 50px;

    display: flex;
    justify-content: center;
    align-items: center;
}

.footer .info .icons .item a {
    height: 100%;
    width: 100%;
    background-color: white;
    border-radius: 25px;
    display: flex;
    justify-content: space-between;
    align-items: center;

}

.footer .info .icons .item a img {
    width: 40px;
    margin: 0 auto;
    padding-right: 1px;
    padding-top: 5px;
}

.mobileFooter {
    background-color: #3f5070;
    width: 100%;
    position: absolute;
}

.mobileFooter .info {
    width: 90%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.mobileFooter .info .left {
    color: white;
    font-size: 18px;
    gap: 30px;
    padding-top: 30px;
    padding-bottom: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.mobileFooter .info .left a {
    color: white;
    font-size: 18px;
    text-decoration-line: underline;
    cursor: pointer;
    display: flex;
    align-items: center;
    text-align: center;
}

.mobileFooter .info .title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 7px;
}

.mobileFooter .info .title .upper {
    text-align: center;
}

.mobileFooter .info .title .upper a {
    color: white;
    text-decoration-line: none;
    text-align: center;
}

.mobileFooter .info .title .bottom {
    color: white;
    text-decoration-line: none;
    text-align: center;
}

.mobileFooter .info .icons {
    padding-top: 30px;
    padding-bottom: 15px;
    gap: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.mobileFooter .info .icons .item {

    height: 50px;
    width: 50px;

    display: flex;
    justify-content: center;
    align-items: center;
}

.mobileFooter .info .icons .item a {
    height: 100%;
    width: 100%;
    background-color: white;
    border-radius: 25px;
    display: flex;
    justify-content: space-between;
    align-items: center;

}

.mobileFooter .info .icons .item a img {
    width: 40px;
    margin: 0 auto;
    padding-right: 1px;
    padding-top: 5px;
}

@media screen and (max-width: 1330px) {
    .footer .info a {
        font-size: 17px;
    }
    .footer .info .title .upper a{
        font-size: 17px;
    }
}

@media screen and (max-width: 1230px) {
    .footer .info a {
        font-size: 16px;
    }
    .footer .info .title .upper a{
        font-size: 16px;
    }
}

@media screen and (max-width: 1130px) {
    .footer .info a {
        font-size: 15px;
    }
    .footer .info .title .upper a{
        font-size: 15px;
    }
}

@media screen and (max-width: 1030px) {
    .footer .info a {
        font-size: 14px;
    }
    .footer .info .title .upper a{
        font-size: 14px;
    }
}