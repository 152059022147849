.main {
    width: 90%;
    margin: 0 auto;
    max-width: 1300px;
    padding-bottom: 10px;
    min-height: calc(100vh - 385px);
}

.recommend {
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 20px;
}
.newsRecommend {
    margin: 0 auto;
    width: 90%;
    max-width: 1300px;
    padding-top: 30px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 20px;
}

.banner1 {
    width: 100%;
    background-color: #FAF6F8;
    height: 400px;
}

.banner2 {
    background-color: #F2ECF1;
    height: 400px;
    width: 100%;
    display: flex;
    align-items: center;
}

.banner2 .infoPart {
    width: 90%;
    max-width: 1300px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.banner2 .infoPart img {
    max-width: 33%;
}

.banner1 .infoPart {
    width: 90%;
    max-width: 1300px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.banner1 .infoPart .left {
    max-width: 400px;
    width: 31%;
    display: flex;
    flex-direction: column;
    gap: 15px;
    justify-content: center;
}

.banner1 .infoPart .left .upper {
    color: #CB2D5F;
    font-size: 48px;
    font-weight: bold;
}

.banner1 .infoPart .left .bottom {
    font-size: 20px;
}

.banner1 .infoPart .pic {

    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    height: 100%;
    min-height: 400px;
}

.banner1 .infoPart .pic img {
    max-width: 100%;
}

.bigMainTitle {
    width: 100%;
    font-size: 30px;
    font-weight: 600;
}


.courseList {
    padding-bottom: 50px;
}

.instruction {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    padding-top: 30px;
}

.instructionItem {
    flex-basis: 25%;
    max-width: 33%;
    font-size: 18px;
    color: #3F5070;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    font-weight: 600;
    padding-bottom: 20px;
}

.instructionItem a {
    color: #3F5070;
}

.instructionItem .instImg img {
    width: 100%;
    height: 100%;
    max-height: 270px;
    object-fit: cover;
    border-radius: 215px;
}

.instImg {
    max-width: 270px;
    max-height: 270px;
    margin-bottom: 20px;
}

.filterPage {
    display: flex;
    flex-direction: row;
    gap: 80px;
    padding-top: 20px;
}

.filterPage .filePart {
    width: calc(75% - 80px);
}

.newFilterPage {
    display: flex;
    flex-direction: row;
    gap: 20px;
    padding-top: 20px;
}

.newFilterPage .coursePart {
    width: calc(75% - 30px);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    column-gap: 5%;
    row-gap: 20px;
}


.websList {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 30px;
    padding-top: 30px;
    padding-bottom: 20px;
}

.websItem {
    flex-basis: calc(33% - 20px);
    max-width: calc(33% - 20px);
    height: 330px;
    margin-bottom: 30px;
}

.websItem a {
    height: 100%;
    width: 100%;
    text-decoration-line: none;
    color: black;
    font-size: 15px;
    transition: all 0.3s ease;
    padding-bottom: 30px;
    background-color: white;
    border-radius: 30px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
}

.websItem a .calendarInfo .type {
    font-size: 16px;
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 10px;
}

.websItem a .calendarInfo .date {
    padding: 10px 30px;
    font-size: 16px;
}

.websItem a:hover {
    cursor: pointer;
    background-color: #f7dde5;
}

.websItem .title {
    padding: 30px 30px 20px;
    font-size: 16px;
    line-height: 1.5;
    font-weight: bold;
}

.websItem:hover .title {
    text-decoration-line: underline;
}

.teacherList {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    padding-top: 40px;
    gap: 30px;
}

.teacherBlock {
    flex-basis: calc(50% - 15px);
    max-width: calc(50% - 15px);
    display: flex;
    flex-direction: row;
    gap: 25px;
    line-height: 1.5;
}

.teacherBlock img {
    width: 180px;
    height: 200px;
    border-radius: 10px;
}

.teacherBlock .info {
    display: flex;
    flex-direction: column;
    gap: 20px
}

.teacherBlock .info .name {
    font-size: 18px;
    color: #3F5070;
    font-weight: 600;


}

.teacherBlock .info .position {
    font-size: 15px;
    color: black;
    font-weight: 300;

}

.teacherBlock .info .dopInfo {
    font-size: 15px;
    color: black;
    font-weight: 300;
    padding-bottom: 10px;

}

.cabinetTitle {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    padding-bottom: 30px;
}

.cabinetTitle .left {

}

.cabinetTitle .right {

}

.cabinetEdit {

}

.cabinetEdit a {
    display: inline-block;
    background-color: #df7a9a;
    color: white;
    padding: 16px 25px;
    text-decoration-line: none;
    border-radius: 15px;
}

.logout button {
    font-size: 16px;
    border: none;
    cursor: pointer;
    display: inline-block;
    background-color: red;
    color: white;
    padding: 16px 25px;
    text-decoration-line: none;
    border-radius: 15px;
}

.smallPanel {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    row-gap: 15px;
    column-gap: 15px;
}

@media screen and (max-width: 1400px) {

    .websList {
        gap: 5%;
    }

    .websItem {
        flex-basis: 30%;
        max-width: 30%;
        margin-bottom: 60px;
    }
}

@media screen and  (max-width: 1280px) {

    .websItem {
        height: 400px;
    }

    .banner1 .infoPart .pic {
        width: 60%;
    }
}

@media screen and (max-width: 1100px) {
    .newFilterPage .coursePart {
        column-gap: 4%;
    }
}

@media screen and (max-width: 1050px) {

    .websList {
        gap: 50px
    }

    .websItem {
        flex-basis: calc(50% - 25px);
        max-width: calc(50% - 25px);
        height: 300px;
        margin-bottom: 30px;
    }

    .websItem img {
        aspect-ratio: 320/175;
        height: auto;
    }

    .teacherBlock {
        flex-basis: 100%;
        max-width: 100%;
        padding-bottom: 20px;
    }
}

@media screen and (max-width: 976px) {

    .websList {
        gap: 20px;
    }

    .instructionItem {
        flex-basis: 51%;
        max-width: 51%;
    }

    .filterPage {
        flex-direction: column;
        gap: 20px;
    }

    .filterPage .filePart {
        width: 100%;
    }

    .newFilterPage {
        flex-direction: column;
        gap: 20px;
    }

    .newFilterPage .coursePart {
        width: 100%;
        justify-content: space-between;
    }


    .websItem {
        flex-basis: 100%;
        max-width: 100%;
    }

    .websItem {
        height: auto;
        aspect-ratio: auto;
    }


    .banner1 .infoPart .pic {
        justify-content: flex-end;
    }

    .banner2 {
        height: min-content;
    }

    .banner2 .infoPart {
        flex-direction: column;
        align-items: center;
        gap: 20px;
        padding-top: 20px;
        padding-bottom: 20px;
    }

    .banner2 .infoPart img {
        max-width: max-content;
    }
}

@media screen and (max-width: 900px) {
    .banner1 {
        height: min-content;
    }

    .banner1 .infoPart {
        flex-direction: column;
    }

    .banner1 .infoPart .left {
        flex-direction: row;
        width: 100%;
        max-width: 100%;
        padding-top: 30px;
    }

    .banner1 .infoPart .left .upper {
        width: 50%;
    }

    .banner1 .infoPart .left .bottom {
        width: 50%;
    }

    .banner1 .infoPart .pic {
        min-height: 0;
        flex-direction: row;
        justify-content: center;
        width: 100%;
    }
}


.formName {
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
    max-width: 100px
}


.bigTitle {
    width: 100%;
    color: black;
    font-size: 24px;
    font-weight: 600;
    padding-top: 20px;
    padding-bottom: 20px;
}

.contacts a {
    color: black;
    font-size: 20px;
    text-decoration-line: none;
}

.contacts a:hover {
    text-decoration-line: underline;
}

.mainDiploms {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 30px;
}

.mainDiploms img {
    max-width: 100%;
    height: 317px;
}

.coursesMain {
    display: flex;
    flex-wrap: wrap;
    gap: 5%;
    justify-content: flex-start;
}
.newsMain {
    width: 90%;
    max-width: 1300px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    gap: 4%;
    justify-content: flex-start;
}

.loginForm {
    display: flex;
    flex-wrap: wrap;
    border: 1px solid #c9c9c9;
    border-radius: 20px;
    flex-direction: column;
    background-color: white;
    padding: 20px;
    margin: 0 auto;
    max-width: 400px;
    margin-top: 40px;
}





.loginForm h1 {
    color: black;
    width: 100%;
    font-size: 28px;
    text-align: center;
}

.loginForm .loginInfo {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
}

.loginForm .loginInfo ul {
    font-size: 18px;
}

.loginForm .loginInfo ul li {
    padding-bottom: 8px;
}

.loginForm .loginInfo .wrong {
    color: red;
    padding-bottom: 5px;
    margin-bottom: 5px;
}

.registration {
    font-size: 18px;
    text-align: center;

}
.registration a {
    font-weight: bold;
    color: #000000;

}

.loginForm .loginInfo input[type=username] {
    font-size: 21px;
    min-height: 40px;
    border-radius: 15px;
    border: 1px solid #c9c9c9;
    padding: 5px 15px;
    margin-bottom: 15px;
}

.loginForm .loginInfo input[type=email] {
    font-size: 21px;
    min-height: 40px;
    border-radius: 15px;
    border: 1px solid #c9c9c9;
    padding: 5px 15px;
    margin-bottom: 15px;
}

.loginForm .loginInfo input[type=text] {
    font-size: 21px;
    min-height: 40px;
    border-radius: 15px;
    border: 1px solid #c9c9c9;
    padding: 5px 15px;
    margin-bottom: 15px;
}
.loginForm .loginInfo input[type=password] {
    font-size: 21px;
    min-height: 40px;
    border-radius: 15px;
    border: 1px solid #c9c9c9;
    padding: 5px 15px;
    margin-bottom: 15px;
}

.loginForm .loginInfo button {
    border: none;
    font-size: 21px;
    min-height: 40px;
    border-radius: 15px;
    color: white;
    background-color: #df7a9a;
    padding: 5px 15px;
    margin-top: 40px;
    margin-bottom: 15px;
    cursor: pointer;
}

.loginForm .loginInfo label {
    font-size: 21px;
    margin-bottom: 10px;
}

@media screen and (max-width: 1400px) {

    .formName {
        max-width: 175px;
    }

}


@media screen and (max-width: 1130px) {
    .coursesMain {
        column-gap: 4%;
        row-gap: 30px;
    }
    .newsMain {
        column-gap: 4%;
        row-gap: 30px;
    }
}


@media screen and (max-width: 825px) {
    .banner1 .infoPart .left {
        flex-direction: column;
    }
}

@media screen and (max-width: 750px) {
    .coursesMain {
        row-gap: 0;
        column-gap: 40px;
        justify-content: center;
        align-items: center;
    }
    .newsMain {
        row-gap: 0;
        column-gap: 40px;
        justify-content: center;
        align-items: center;
    }
}

@media screen and (max-width: 550px) {
    .teacherList {
        gap: 10px
    }

    .recommend {
        flex-direction: column;
        gap: 15px;
    }
    .newsRecommend {
        flex-direction: column;
        gap: 15px;
    }

    .teacherBlock {
        flex-direction: column;
        padding-bottom: 0;
    }

    .teacherBlock .info {
        max-width: 100%;
    }

    .teacherBlock .info .name {
        height: 20px;
    }

    .teacherBlock .info .position {
        height: 60px;
    }

    .banner1 .infoPart .left .upper {
        font-size: 40px;
    }

    .mainDiploms img {
        height: auto;
    }
}

