.editInfo {
    width: 90%;
    margin: 0 auto;
    font-size: 20px;
    color: #808080;
}

.editInfo .formStyle {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    column-gap: 10%;
}

.editInfo .formStyle .item {
    display: flex;

    flex-basis: 45%;
    max-width: 45%;
    flex-direction: column;
    margin-bottom: 10px;
}
.editInfo .formStyle .item a {
    color: #000000;
    text-decoration-line: underline;
    font-size: 19px;
}
.editInfo .formStyle .item .wrong {
    color: red;
}

.editInfo .formStyle .item .mid input {
    font-size: 22px;
    color: black;
    padding: 10px 15px;
    border-radius: 15px;
    margin-top: 10px;
    margin-bottom: 10px;
    width: calc(100% - 32px);
}
.editInfo .formStyle .item .mid input[type=file] {

    margin-bottom: 20px;
}

.editInfo .formStyle .item .toggle {
    padding-bottom: 10px;
    padding-top: 10px;
    height: 49px;
    display: flex;
    align-items: center;
    color: #000000;
    column-gap: 10px;
}

.editInfo .formStyle .item .toggle input[type=checkbox] {
    height: 18px;
    width: 18px;
}

.editInfo .formStyle .item button {
    background-color: #df7a9a;
    color: white;
    padding: 16px 25px;
    text-decoration-line: none;
    border-radius: 15px;
    font-size: 18px;
    border: none;
    cursor: pointer;
    margin-top: 10px;
    margin-bottom: 10px;
}

.editInfo .formStyle .item .mid optgroup {
    font-size: 22px;
    color: grey;
    padding: 10px 15px;
    border-radius: 15px;
    margin-top: 10px;
    margin-bottom: 10px;
    width: 100%;
    max-width: 100%;
}

.editInfo .formStyle .item .mid select {
    font-size: 22px;
    color: black;
    padding: 10px 15px;
    border-radius: 15px;
    margin-top: 10px;
    margin-bottom: 10px;
    width: 100%;
    max-width: 100%;
}

.editInfo .formStyle .item .mid option {
    color: black;
}

.final {
    font-size: 19px;
    width: 100%;
    margin-bottom: 10px;
}

.final .toggle {
    padding-bottom: 10px;
    padding-top: 10px;
    height: 49px;
    display: flex;
    align-items: center;
    color: #000000;
    column-gap: 10px;
}

.final input[type=checkbox] {
    height: 24px;
    width: 24px;
}

.final button {
    background-color: #df7a9a;
    color: white;
    padding: 16px 25px;
    text-decoration-line: none;
    border-radius: 15px;
    font-size: 18px;
    border: none;
    cursor: pointer;
    margin-top: 10px;
    margin-bottom: 10px;
}

.final .wrong {
    color: red;
}

@media screen and (max-width: 976px){
    .editInfo .formStyle .item {
        flex-basis: 100%;
        max-width: 100%;

    }
}