:root {
    --fc-today-bg-color: #f7dde5;
    --fc-event-bg-color: #E07B9B;
    --fc-event-border-color: #E07B9B;
}

.tippy-box[data-theme~='edu'] {
    background-color: #EAF1F4;
    color: #000000;
    border: 1px solid #CCCCCC;
    padding: 5px;
}