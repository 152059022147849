.modal {
    margin: 0 auto;
    padding-top: 20px;
}
.modal button {
    font-family: 'Jost', sans-serif;
    width: 45%;
}
.nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}
.form {
    font-size: 20px;
    width: 90%;
    margin: 0 auto;
    margin-bottom: 55px;
    margin-top: 50px;
    padding: 40px;
    background-color: white;
    border: 1px solid #CCCCCC;
    max-width: 600px;
    border-radius: 20px;
    line-height: 1.4;
}

.form li {
    padding-bottom: 6px;
}

.form h1 {
    font-size: 24px;
    text-align: center;
}
.form input:-internal-autofill-selected {
    appearance: menulist-button;
    background-image: none !important;
    background-color: white !important;
    color: fieldtext !important;
}
.form input:-webkit-autofill{
    box-shadow:inset 0 0 0 1000px #fff;
}

.error {
    color: red;
    font-size: 16px;
    font-weight: bold;
    text-align: justify;
}

.info {
    color: black;
    font-size: 16px;
}
.info h2 {
    color: #4D4D4D;
    font-size: 32px;
}
.center {
    display: flex;
    justify-content: center;
}