.newsCard {
    flex-basis: 22%;
    max-width: 22%;
    border-radius: 30px;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
}

.newsCard .courseImage {
    margin-bottom: 15px;
    height: 150px;
    width: 100%;
}

.newsCard .courseImage img {
    width: 100%;
    object-fit: cover;
    height: 100%;

}


.newsCard .courseTitle {
    height: 100px;
    font-size: 18px;
    width: 100%;
    text-overflow: ellipsis;
}


.newsCard .bottom {
    height: 60px;
    font-size: 12px;
    color: black;
    display: flex;
    justify-content: space-between;
    width: 100%;

}

@media screen and (max-width: 1130px) {

    .newsCard {
        flex-basis: 48%;
        max-width: 48%;

        height: 500px;
    }
}



@media screen and (max-width: 750px) {
    .newsCard {
        flex-basis: 100%;
        max-width: 509px;

    }
}

@media screen and (max-width: 400px){
    .newsCard {
        height: 700px;
    }
}