.webWindow {
    flex-basis: 30%;
    border-radius: 30px;
    background-color: white;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
}

.webWindow .courseImage {
    width: 100%;
    height: 220px;
    padding-bottom: 30px;
}

.webWindow .courseImage img {

    width: 100%;
    height: 100%;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
}

.webWindow .word {
    text-align: left;
    font-size: 12px;
    height: 35px;
    width: calc(100% - 30px);
}

.webWindow .courseTitle {
    font-weight: bold;
    height: 90px;
    font-size: 15px;
    width: calc(100% - 30px);
}

.webWindow .info {
    width: calc(100% - 30px);
    height: 70px;
    display: flex;
}

.webWindow .info .left {
    width: calc(50% + 15px);
    font-size: 12px;
    text-wrap: normal;
    text-align: left;
    display: flex;
    justify-content: left;
    align-items: center;
}

.webWindow .info .right {
    width: calc(50% + 15px);
    text-wrap: normal;
    white-space: normal;
    overflow-wrap: break-word;
    text-align: right;
    font-weight: bold;
    display: flex;
    justify-content: right;
    align-items: center;
    font-size: 18px;
}

.webWindow .bottom {
    height: 60px;
    font-size: 20px;
    color: white;
    display: flex;
    justify-content: space-between;
    width: 100%;

}
.webWindow .bottom .left {
    width: calc(50%);
}

.webWindow .bottom .left a {
    height: 60px;
    width: 100%;
    border-bottom-left-radius: 30px;
    background-color: #3f5070;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration-line: none;
    cursor: pointer;
    color: white;
    font-size: 12px;
}

.webWindow .bottom .right {
    width: calc(50%);

}

.webWindow .bottom .right a{
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration-line: none;
    cursor: pointer;
    color: white;
    height: 60px;
    width: 100%;
    border-bottom-right-radius: 30px;
    background-color: #e07b9b;
    font-size: 12px;
}



@media screen and (max-width: 1231px){

    .webWindow .courseImage {
        padding-bottom: 10px;
    }

    .webWindow {
        height: 500px;
        gap: 0;
    }
    .webWindow .courseTitle {
        height: 150px;
    }
}

@media screen and (max-width: 1100px){
    .webWindow {
        justify-content: flex-start;
        max-width: 48%;
        flex-basis: 48%;
        height: 470px;
        gap: 0;
    }
    .webWindow .courseTitle {
        height: 60px;
        padding-bottom: 10px;
    }
    .webWindow  .word{
        height: 25px;
    }
    .webWindow .courseImage {
        padding-bottom: 20px;
    }
}


@media screen and (max-width: 1050px) {
    .allCoursesPage {
        flex-direction: column;
        gap: 20px;
    }

    .webWindow {
        margin-left: 0;
    }

}

/*@media screen and (max-width: 976px) {*/
/*    .webWindow {*/
/*        max-width: 30%;*/
/*        flex-basis: 30%;*/
/*    }*/
/*}*/

@media screen and (max-width: 800px) {
    .webWindow {
        width: 47%;
    }
}

@media screen and (max-width: 700px) {
    .webWindow .courseTitle{
        height: 100px;
    }
}

@media screen and (max-width: 625px) {
    .webWindow {
        flex-basis: 100%;
        column-gap: 0;
        max-width: 100%;
        height: max-content;
    }
    .webWindow .courseTitle {
        height: 50px;
    }
    .webWindow .courseImage {
        width: 100%;
    }
    .webWindow .courseImage img {
        width: 100%;
        max-width: 100%;
        height: 100%;
        max-height: max-content;
        object-fit: cover;
    }
}