.pageTitle {
    font-weight: 600;
    font-size: 30px;
    color: black;
    width: 100%;
}

.pageSubtitle {
    font-weight: 300;
    font-size: 18px;
    color: black;
    width: 100%;
    padding-top: 5px;
}

.search {
    margin-top: 10px;
    display: flex;
    align-items: center;
}

.search button {
    color: white;
    font-size: 28px;
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    height: 52px;
    width: 50px;
    border: 1px solid #e07b9b;
    background-color: #e07b9b;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}

.search button:hover {
    background-color: #EF99B6;
    border: 1px solid #EF99B6;
}

.search input[type=text] {
    font-size: 18px;
    width: calc(100% - 65px);
    height: 50px;
    border: 1px solid #ccc;
    padding: 0 0 0 20px;
    border-bottom-left-radius: 10px;
    border-top-left-radius: 10px;
}

.filterPage {
    display: flex;
    flex-direction: row;
    gap: 80px;
    padding-top: 20px;
}

.filter {
    width: 25%;
    display: block;
    border: 1px solid #ccc;
    border-radius: 20px;
    background-color: white;
    height: min-content;
}

.courseFilter {
    width: 100%;
    display: block;
    border: 2px solid #df7a9a;
    border-radius: 20px;
    background-color: white;
    height: min-content;
}

.courseFilter .filterTitle {
    width: 72%;
    margin: 0 auto;
    padding-top: 15px;
    padding-bottom: 5px;
    font-size: 24px;
    font-weight: bolder;
    color: #212529;
}

.filter .filterTitle {
    width: 80%;
    margin: 0 auto;
    padding-top: 15px;
    padding-bottom: 5px;
    font-size: 18px;
    font-weight: bolder;
    color: #212529;
}


.filter .filterRefs {
    display: block;
    height: min-content;
    margin-bottom: 8px;
}

.courseFilter .filterRefs {
    display: block;
    height: min-content;
    margin-bottom: 35px;
    margin-top: 5px;

}

.href:last-child {
    border-bottom: none;
}

.filter .filterRefs a {
    color: #000000;
    text-decoration-line: none;
}

.courseFilter .filterRefs a {
    color: #000000;
    text-decoration-line: none;
    font-size: 20px;
}

.href {
    width: 90%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    border-bottom: 1px solid #cccccc;
}

.href a {
    color: #000000;
    padding-top: 13px;
    width: 80%;
    padding-bottom: 13px;
    font-size: 18px;
}

.href a:hover {
    color: #cb2d5f;
}


.qBlock {
    border-radius: 20px;
    border: 1px solid #cccccc;
    background-color: white;
    margin-bottom: 20px;
}

.qBlock .title {
    padding-top: 20px;
    color: #808080;
    font-size: 28px;
    width: 100%;
    padding-bottom: 10px;
}

.qBlock summary {
    font-weight: 600;
    color: black;
    font-size: 18px;
    padding-top: 10px;
    padding-bottom: 10px;
    cursor: pointer;
    list-style: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.qBlock summary::-webkit-details-marker {
    display: none
}


.qBlock details[open] summary {
    color: #CB2D5F;
}


.answer {
    font-size: 18px;
    color: #808080;
    border-bottom: 1px solid #cccccc;
    padding-top: 10px;
    padding-bottom: 20px;
}

.qBlock .questions {
    padding-bottom: 20px;
}


.controlIconClose {
    display: none;
}

details[open] .controlIconClose {
    display: initial;
    transition: .3s ease;
}

details[open] .controlIconExpand {
    display: none;
}

summary::-webkit-details-marker {
    display: none
}

.qBlock .support {
    width: 95%;
    margin: 0 auto;
    padding-bottom: 10px;
}

.qBlock .support .title {
    color: #808080;
    font-size: 28px;
    width: 100%;
    padding-bottom: 20px;
}

.qBlock .support .block {
    display: flex;
    flex-direction: row;
    gap: 10px;
    padding-bottom: 10px;
    justify-content: space-between;
}

.qBlock .support .block .contacts {
    display: flex;
    width: calc(30% - 32px);
    flex-direction: column;
    gap: 10px;
}

.qBlock .support .block .contacts label input {
    width: 100%;
    font-size: 22px;
    padding: 15px 12px;
    border-radius: 16px;
    border: 1px #808080 solid;
}

.qBlock .support .block .question {
    width: calc(70% - 10px);
    height: max-content;
}

.qBlock .support .block .question textarea {
    width: calc(100% - 48px);
    height: 230px;
    border-radius: 16px;
    border: 1px #808080 solid;
    font-size: 22px;
    padding: 15px 20px;
}

.qBlock .support .supBtn {

    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.qBlock .support .supBtn input {
    display: inline-block;
    background-color: #E07B9B;
    color: white;
    border: none;
    font-size: 18px;
    padding: 18px 40px;
    border-radius: 29px;
}

.profileBlock {
    border-radius: 20px;
    border: 1px solid #cccccc;
    background-color: white;
    margin-bottom: 20px;
}

.profileBlock summary {
    font-weight: 300;
    color: #808080;
    font-size: 30px;
    padding-bottom: 20px;
    cursor: pointer;
    list-style: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.profileBlock .profile {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding-bottom: 20px;
    gap: 40px;
}

.profileBlock .profile img {
    width: 200px;
    height: 200px;
    border-radius: 100px;
}

.profileBlock .profile .profileInfo {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.profileBlock .profile .profileInfo .fio {
    color: #CB2D5F;
    font-size: 30px;
}

.profileBlock .profile .profileInfo .otherInfo {
    color: #808080;
    font-size: 18px;
}

.yourCoursesBlock {
    border-radius: 20px;
    border: 1px solid #cccccc;
    background-color: white;
    margin-bottom: 20px;
}

.yourCoursesBlock summary {
    font-weight: 300;
    color: #808080;
    font-size: 30px;
    padding-bottom: 20px;
    cursor: pointer;
    list-style: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.yourCoursesBlock .yourCoursesList {
    padding-top: 20px;
    font-size: 18px;
    color: #808080;
}

.yourCoursesBlock .yourCoursesList .tableNames {
    display: flex;
    flex-direction: row;
    font-size: 12px;
    padding-bottom: 10px;
    gap: 20px;
    justify-content: space-between;
}

.yourCoursesBlock .yourCoursesList .course {
    padding-top: 10px;
    display: flex;
    flex-direction: row;
    min-height: 32px;
    padding-bottom: 10px;
    border-top: 1px solid #cccccc;
    gap: 20px;
    justify-content: space-between;
}

.left {
    width: calc(73% - 10px);
}

.yourCoursesBlock .yourCoursesList .course .left {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}
.yourCoursesBlock .yourCoursesList .course .left a {
    width: calc(100% - 60px);
}

.yourCoursesBlock .yourCoursesList .course .left svg {
    padding-right: 10px;
}

.center {
    width: 7%;
}

.right {
    width: 15%;
}

.yourCoursesBlock .yourCoursesList .course .right {
    display: flex;
    flex-direction: column;
}

.profileBlock .courseDescription {
    padding-top: 20px;
    padding-bottom: 20px;
    font-size: 18px;
    line-height: 1.3;
}

.profileBlock .courseDescription br {
    content: "";
    margin: 1.5em;
    display: block;
    margin-bottom: -20px;
}

.profileBlock .courseDescription p {
    font-size: 36px;
}


.profileBlock .courseDescription li {
    margin-bottom: 8px;
}

.profileBlock .courseDescription .ref a {
    color: #3F5070;
    font-size: 18px;
}

.qInput {
    padding-bottom: 40px;
    display: flex;
    align-items: center;
}

.qInput button {
    color: white;
    font-size: 15px;
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    height: 52px;
    width: 150px;
    border: none;

    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}

.qInput button:hover {
    background-color: #EF99B6;
    border: 1px solid #EF99B6;
}

.qInput input[type=text] {
    font-size: 18px;
    width: calc(100% - 125px);
    height: 50px;
    border: 1px solid #ccc;
    padding: 0 0 0 20px;
    border-bottom-left-radius: 10px;
    border-top-left-radius: 10px;
}

.send a {
    border-radius: 30px;
    padding-top: 20px;
    padding-bottom: 20px;
    font-size: 18px;
    display: inline-block;
    text-align: center;
    width: 270px;
    color: white;
    text-decoration-line: none;
    background-color: #E07B9B;
}

@media screen and (max-width: 976px) {
    .courseFilter {
        margin-top: 25px;
        margin-bottom: 25px;
    }
    .pageTitle {
        padding-top: 20px;
    }

    .filter {
        width: 100%;
    }

    .qBlock .support .block {
        flex-direction: column;
    }

    .qBlock .support .block .contacts {
        width: calc(100% - 32px);
    }

    .qBlock .support .block .question {
        width: 100%;
    }

    .qBlock .support .block .question textarea {
        padding: 15px 12px;
        width: calc(100% - 32px);
    }

    .profileBlock .profile {
        flex-direction: column;
    }

    .left {
        width: 50%;
    }

    .yourCoursesBlock .yourCoursesList .tableNames {
        gap: 10px
    }

    .yourCoursesBlock .yourCoursesList .course {
        gap: 10px
    }

    .center {
        width: 15%;
    }

    .right {
        width: 20%;
    }
}