.carouselContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    max-height: 600px;
}

.carouselContainer img {
    width: 100%;
    max-height: 600px;
}

.carouselWrapper {
    display: flex;
    width: 100%;
    position: relative;
}

.carouselContentWrapper {
    overflow: hidden;
    width: 100%;
    height: 100%;
}

.carouselContent {
    display: flex;
    margin: 0 auto;
    transition: all 250ms linear;
    -ms-overflow-style: none; /* hide scrollbar in IE and Edge */
    scrollbar-width: none; /* hide scrollbar in Firefox */

}

/* hide scrollbar in webkit browser */
.carouselContent::-webkit-scrollbar, .carouselContent::-webkit-scrollbar {
    display: none;
}

.carouselContent > * {
    width: 100%;
    flex-shrink: 0;
    flex-grow: 1;
}

.carouselContent.show3 > * {
    width: 30%
}

.carouselContent.show4 > * {
    width: 22%
}

.leftArrow, .rightArrow {
    position: absolute;
    z-index: 1;
    top: 50%;
    transform: translateY(-50%);
    width: 40px;
    height: 40px;
    border-radius: 20px;
    background-color: transparent;
    border: 1px solid white;
    padding: 0;
}

.rightArrow svg path {
    stroke: white;
}
.leftArrow svg path {
    stroke: white;
}

.leftArrow {
    left: 2%;
}

.rightArrow {
    right: 2%;
}



.slide {

}

.slide img {
    z-index: 0;

}

.slide div a {
    position: absolute;
    font-size: 36px;
    color: white;
    text-decoration-line: none;
    top: 25%;
    z-index: 2;
    left: 10%;
    font-weight: 700;
    max-width: 60%;
}

.slide span a {
    position: absolute;
    bottom: 15%;
    left: 10%;
    z-index: 2;
}

.recommend {
    padding-top: 50px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.recommend .left .bottom {
    font-size: 18px;
}

.recommend .left {
    max-width: 510px;
}

.recommend .right {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    width: 300px;
    justify-content: space-between;
    align-items: center;
}

.rightBtns {
    display: flex;
    align-items: center;
    justify-content: space-around;
    min-width: 148px;

}

.courseWindow {
   flex-basis: 30%;
    max-width: 30%;
    border-radius: 30px;
    background-color: white;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
}

.courseWindow .courseImage {
    padding-bottom: 30px;
    height: 300px
}

.courseWindow .courseImage img {
    height: 100%;
    object-fit: cover;
    width: 100%;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
}

.courseWindow .word {
    text-align: left;
    font-size: 12px;
    height: 35px;
    width: calc(100% - 30px);
}

.courseWindow .courseTitle {
    font-weight: bold;
    height: 75px;
    font-size: 15px;
    width: calc(100% - 30px);
}

.courseWindow .info {
    width: calc(100% - 30px);
    height: 70px;
    display: flex;
}

.courseWindow .info .left {
    width: calc(50% + 15px);
    font-size: 12px;
    text-wrap: normal;
    text-align: left;
    display: flex;
    justify-content: left;
    align-items: center;
}

.courseWindow .info .right {
    width: calc(50% + 15px);
    text-wrap: normal;
    white-space: normal;
    overflow-wrap: break-word;
    text-align: right;
    font-weight: bold;
    display: flex;
    justify-content: right;
    align-items: center;
    font-size: 18px;
}

.courseWindow .bottom {
    height: 60px;
    font-size: 20px;
    color: white;
    display: flex;
    justify-content: space-between;
    width: 100%;

}
.courseWindow .bottom .left {
    width: calc(50%);
}

.courseWindow .bottom .left a {
    height: 60px;
    width: 100%;
    border-bottom-left-radius: 30px;
    background-color: #3f5070;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration-line: none;
    cursor: pointer;
    color: white;
    font-size: 12px;
}

.courseWindow .bottom .right {
    width: calc(50%);

}

.courseWindow .bottom .right a{
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration-line: none;
    cursor: pointer;
    color: white;
    height: 60px;
    width: 100%;
    border-bottom-right-radius: 30px;
    background-color: #e07b9b;
    font-size: 12px;
}

.teacher {
    display: flex;
    flex-direction: column;
    margin-right: 4%;
}

.teacher .teacherPhoto {
    width: 100%;
}

.teacher .teacherPhoto img {
    width: 100%;
    max-width: 100%;
}

.teacher .name {
    font-size: 18px;
    font-weight: 600;
    color: #3F5070;
    width: 100%;
    padding-bottom: 10px;
}

.teacher .position {
    font-size: 15px;
    width: 100%;
    height: 100px;
}

.newCarousel {
    padding-top: 15px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

@media screen and (max-width: 1330px) {
    .slide div a {
        font-size: 34px;
        top: 20%;
    }

}

@media screen and (max-width: 1230px) {
    .slide div a {
        font-size: 32px;
    }
}

@media screen and (max-width: 1130px) {
    .slide div a {
        font-size: 30px;
        top: 15%;
    }
    .courseWindow {
        flex-basis: 48%;
        max-width: 48%;

        height: 500px;
    }
}

@media screen and (max-width: 1030px) {
    .slide div a {
        font-size: 28px;
    }
}

@media screen and (max-width: 976px) {
    .slide span a {
        display: none;
    }
    .slide div a {
        font-size: 3.5vw;
        font-weight: 300;
        left: 15%;
    }
    .leftArrow, .rightArrow {
        border: 0;
    }
    .carouselContent.show3 > * {
        width: 45%
    }
    .carouselContent.show4 > * {
        width: 45%
    }
    .courseWindow {
        flex-basis: 48%;


    }
    .teacher {
        margin-right: 10%;
    }
    .newCarousel {
        flex-direction: column;
        gap: 30px;
        margin: 0 auto;
    }
}

@media screen and (max-width: 750px) {
    .courseWindow {
        flex-basis: 100%;
        max-width: 509px;

    }
}

@media screen and (max-width: 625px) {
    .carouselContent.show3 > * {
        width: 100%
    }
    .carouselContent.show4 > * {
        width: 100%
    }

}

@media screen and (max-width: 507px) {
    .recommend {
        flex-direction: column;
        gap: 20px;
    }
}

@media screen and (max-width: 400px){
    .courseWindow {
        height: 700px;
    }
}