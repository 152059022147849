.main {
    width: 90%;
    margin: 0 auto;
    max-width: 1300px;
    padding-bottom: 10px;
    min-height: calc(100vh - 217px);
}

.coursePage {
    display: flex;

    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
}

.coursePage .courseLeft {
    width: calc(78% - 20px);
}

.coursePage .courseRight {
    width: calc(22%);
    display: flex;
    flex-direction: column;
    row-gap: 20px;
}

.courseTeacher .prepod {
    padding-top: 25px;
    padding-bottom: 15px;
    width: 100%;
    font-size: 15px;
}

.courseTeacher .info {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 20px;
}

.courseTeacher .info .name {
    font-size: 18px;
    font-weight: bold;
    color: #3F5070;
    width: 400px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
}

.courseTeacher .info .name img {
    width: 40px;
    padding-right: 10px;
}
.courseTeacher .info .position {
    font-size: 15px;
}

.courseLeft .courseImg {
    margin-top: 40px;
    width: 100%;
    margin-bottom: 20px;
}

.courseLeft .courseImg img{
    width: 100%;
    border-radius: 40px;
}

.buy {
    width: 100%;
    background-color: white;
    border-radius: 30px;
    border: 1px solid #CCCCCC;
    padding-bottom: 30px;
    padding-top: 30px;
}

.buy .info {
    width: 90%;
    margin: 0 auto;
}

.buy .info .buyButton {
    width: 100%;
    margin: 0 auto;

}

.buy .info .buyButton a {
    border-radius: 21px;
    padding-top: 12px;
    padding-bottom: 12px;
    font-size: 15px;
    display: inline-block;
    text-align: center;
    width: 100%;
    color: white;
    text-decoration-line: none;
    background-color: #E07B9B;
}

.send a {
    border-radius: 30px;
    padding-top: 20px;
    padding-bottom: 20px;
    font-size: 18px;
    display: inline-block;
    text-align: center;
    width: 270px;
    color: white;
    text-decoration-line: none;
    background-color: #E07B9B;
}

@media screen and (max-width: 976px) {
    .coursePage {
        flex-direction: column-reverse;
    }

    .coursePage .courseLeft {
        width: 100%;
    }

    .coursePage .courseRight {
        width: 100%;
    }
}