.support {
    display: flex;
    flex-wrap: wrap;
    border: 1px solid #c9c9c9;
    border-radius: 20px;
    justify-content: space-between;
    background-color: white;
    padding: 30px;
    margin: 0 auto;
    margin-top: 20px;
}

.support h2 {
    color: black;
    width: 100%;
    font-size: 28px;
}

.support .contacts {
    width: 30%;
    display: flex;
    flex-direction: column;
    gap: 15px;
    flex-wrap: wrap;
}

.support .contacts input {
    width: 90%;
    margin-bottom: 20px;
    min-height: 40px;
    font-size: 17px;
    border-radius: 10px;
    border: 1px solid #c9c9c9;
    padding-left: 10px;
}

.support .question textarea {
    font-size: 17px;
    width: calc(100% - 10px);
    border-radius: 10px;
    padding-left: 15px;
    padding-top: 10px;
    min-height: 266px;
    border: 1px solid #c9c9c9;
}

.support .question {
    width: 70%;
    min-height: 266px;
}

.support .supBtn {
    margin-top: 15px;
    width: 100%;
}

.support .supBtn input {
    display: block;
    height: 50px;
    font-size: 20px;
    border-radius: 25px;
    background-color: #cb2b62;
    color: white;
    padding: 8px;
    border: none;
    width: 200px;
}

.support .supBtn input:hover {
    cursor: pointer;
}



@media screen and (max-width: 976px) {
    .support {
        width: 80%;
    }

    .support .contacts {
        width: 100%;

    }

    .support .question {
        min-height: 100px;
        width: 100%;
    }

    .support .question textarea {
        width: 90%;
        min-height: 100px;
    }
}