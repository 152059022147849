.avatarUpload img {
    width: 200px;
    height: 200px;
    border-radius: 100%;

}

.avatarUpload {
    width: 100%;
    padding-top: 20px;
    padding-bottom: 50px;
}