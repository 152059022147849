.defaultPinkButton a {
    display: block;
    padding: 18px 40px;
    border-radius: 29px;
    background-color: #E07B9B;
    color: white;
    font-size: 18px;
    text-decoration-line: none;
}

.defaultPinkButton {
    color: white;
}

.grayButton a {
    display: flex;
    width: max-content;
    padding: 10px 15px;
    text-decoration-line: none;
    cursor: pointer;
    color: #cccccc;
    border: 2px solid #cccccc;
    background-color: white;
    border-radius: 20px;
}

.doc a {
    font-size: 18px;
    color: black;
    cursor: pointer;
    text-decoration-line: none;
    line-height: 1.5;
}

.doc {
    padding-bottom: 13px;
}

@media screen and (max-width: 899px) and (min-width: 507px){
    .grayButton {
        margin-bottom: 15px;
    }
}

/*@media screen and (max-width: 1330px) {*/
/*    .defaultPinkButton a {*/
/*        font-size: 17px;*/
/*    }*/
/*}*/

/*@media screen and (max-width: 1230px) {*/
/*    .defaultPinkButton a {*/
/*        font-size: 16px;*/
/*    }*/
/*}*/

/*@media screen and (max-width: 1130px) {*/
/*    .defaultPinkButton a {*/
/*        font-size: 15px;*/
/*    }*/
/*}*/

/*@media screen and (max-width: 1030px) {*/
/*    .defaultPinkButton a {*/
/*        font-size: 14px;*/
/*    }*/
/*}*/